<template>
  <div class="flex-start search-filter-wrap">
    <el-input
      v-model="val"
      width="180"
      placeholder="请输入内容"
      clearable
      @clear="handleFilter"
      @change="valChange"
    />
    <el-button
      size="small"
      type="primary"
      icon="el-icon-search"
      @click="handleFilter"
    >
      搜索
    </el-button>
  </div>
</template>

<script>
export default {
  props: {
    SearchStr: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      val: '',
    }
  },
  created() {
    this.val = this.SearchStr
  },
  methods: {
    valChange() {
      this.$emit('update:SearchStr', this.val)
    },
    handleFilter() {
      this.$emit('update:SearchStr', this.val)
      this.$emit('handleFilter')
    },
  },
}
</script>

<style lang="scss">
 .search-filter-wrap{
     .el-input{
         width: 180px !important;
         margin-right: 10px;
     }
 }
</style>

import {
  downloadByGet, get, post,
} from '@/libs/axios'

// 分页获取隐私字段列表
export const GetPrivacyFieldListByPage = params => post('/privacyField/Page', params)
// 查询字段信息
export const QueryInfo = (classRelationId, filedId) => get(`/privacy/field/info?classRelationId=${classRelationId}&fieldId=${filedId}`)

export const saveOrUpdateField = form => {
  const submitUrl = form.id ? '/privacyField/UpdateField' : '/privacyField/CreateField'
  return post(submitUrl, form)
}
// 删除字段
export const DeleteFieldById = params => post('/privacyField/Delete', params)

// 查询隐私字段下拉
export const GetPrivacyFieldOptionsList = params => post('/privacyField/QueryFieldOption', params)

// 获取所有分类
export const GetAllClass = () => get('/privacyField/class/GetClassTree')
export const GetClassOptions = () => get('/privacy/field/class/select')
// 创建分类
export const CreateClass = params => post('/privacyField/class/CreateClass', params)
// 更新分类
export const UpdateClass = params => post('/privacyField/class/UpdateClass', params)
// 删除分类
export const DeleteClassById = params => post('/privacyField/class/DeleteClassById', params)

// 导入隐私字段模板
export const ImportPrivacyField = params => post('/privacyField/Import', params)
// 导出字段模板
export const ExportPrivacyField = () => downloadByGet('/privacyField/Export')
// 下载导入模板
export const DownloadImportTemplateFile = () => downloadByGet('/privacyField/DownloadImportTemplate')

// 分页获取隐私字段标签列表
export const GetLabelPage = params => post('/privacyField/label/PageLabel', params)
// 创建标签
export const saveOrUpdateLabel = form => {
  const submitUrl = form.id ? '/privacyField/label/UpdateLabel' : '/privacyField/label/CreateLabel'
  return post(submitUrl, form)
}
// 删除标签
export const DeleteLabelById = params => post('/privacyField/label/DeleteLabel', params)
// 标签下拉列表
export const GetLabelOptions = () => get('/privacyField/label/GetLabelOptions')

// 创建/修改标签维度
export const saveOrUpdateDimension = form => {
  const submitUrl = form.id ? '/label/dimension/update' : '/label/dimension/create'
  return post(submitUrl, form)
}
// 删除标签维度
export const DeleteDimensionById = params => post('/privacyField/labelDimension/DeleteDimensionById', params)
// 获取所有标签维度
export const AllDimension = () => get('/privacyField/labelDimension/AllDimension')
// 标签维度树下拉
export const GetLabelDimensionTree = () => get('/privacyField/labelDimension/GetLabelDimensionTree')

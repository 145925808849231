<template>
  <el-dialog :title="title"
             :visible.sync="dialogVisible"
             width="620px"
             @close="dialogClose"
             @open="dialogOpen">
    <el-form v-if="dialogVisible"
             ref="form"
             v-loading="loading"
             :model="form"
             class="assest-save-form"
             label-width="100px">
      <el-form-item label="名称"
                    prop="labelName"
                    :rules="[ { required: true, message: '名称不能为空', trigger: 'change' }]">
        <el-input v-input-filter v-model="form.labelName"/>
      </el-form-item>
      <el-form-item label="维度"
                    prop="dimensionId"
                    :rules="[ { required: true, message: '维度不能为空', trigger: 'change' }]">
        <el-cascader v-model="form.dimensionId"
                     :options="dimensions"
                     :show-all-levels="false"
                     style="width: 100%;"
                     popper-class="cas-class"
                     :props="{ expandTrigger: 'hover', value: 'id', label: 'name',emitPath: false }">
        </el-cascader>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-input-filter v-model="form.remark" />
      </el-form-item>
    </el-form>
    <div slot="footer"
         class="dialog-footer">
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button type="primary"
                 :disabled="loading"
                 @click="submitForm">
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { GetLabelDimensionTree, saveOrUpdateLabel } from '@/api/dictLibrary/dictLibrary'

export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      title: '新增字段标签',
      form: {
        id: 0,
        dimensionId: 0,
        labelName: '',
        remark: '',
      },
      groupOptions: [],
      dimensions: [],
    }
  },
  methods: {
    dialogOpen() {
      if (this.form.id) {
        this.title = '修改字段标签'
      } else {
        this.title = '新增字段标签'
      }
      GetLabelDimensionTree().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.dimensions = resData.data
        }
      })
    },
    dialogClose() {
      this.form = {
        id: 0,
        labelName: '',
        dimensionId: 0,
        remark: '',
      }
      this.$refs.form.resetFields()
    },
    // eslint-disable-next-line vue/no-dupe-keys
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          if (!this.form.id) {
            delete this.form.id
          }
          saveOrUpdateLabel(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter')
            } else {
              error(resData.msg)
            }
          }).catch(() => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style>
.assest-save-form {
  padding-right: 10px;
}
.assest-save-form .el-icon-question {
  position: absolute;
  top: 14px;
  right: -20px;
}
</style>
